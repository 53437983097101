import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getChapter } from '../Actions'
import DOMPurify from 'dompurify'

function NewTestamentPortion() {

  const [torahPortion, setTorahPortion] = useState("");
  const [ntPassage, setNtPassage] = useState("");
  const [ntTitle, setNtTitle] = useState("");
  const [ntText, setNtText] = useState("");

  useEffect(() => {

    const torahToNTMapping = [
      { torah: "Bereshit", nt: "MAT.1.1-MAT.3.12", passage: "Matthew 1:1-3:12", title: "Toldot Yeshua | \"The Generations of Yeshua\"" },
      { torah: "Noach", nt: "MAT.3.13-MAT.4.25", passage: "Matthew 3:13-4:25", title: "L'Hittavel | \"To Be Baptized\"" },
      { torah: "Lech-Lecha", nt: "MAT.5.1-MAT.7.29", passage: "Matthew 5:1-7:29", title: "Ya'al Haharah | \"He Went Up to the Mountain\"" },
      { torah: "Vayeira", nt: "MAT.8.1-MAT.9.38", passage: "Matthew 8:1-9:38", title: "L'tahareni | \"Cleanse Me\"" },
      { torah: "Chayei Sarah", nt: "MAT.10.1-MAT.11.30", passage: "Matthew 10:1-11:30", title: "Sh'neim Asar Talmidaiv | \"His Twelve Disciples\"" },
      { torah: "Toledot", nt: "MAT.12.1-MAT.13.52", passage: "Matthew 12:1-13:52", title: "Bein Hakama | \"Between the Standing Grain\"" },
      { torah: "Vayetze", nt: "MAT.13.53-MAT.15.39", passage: "Matthew 13:53-15:39", title: "El-Artzo | \"His Hometown\"" },
      { torah: "Vayishlach", nt: "MAT.16.1-MAT.17.27", passage: "Matthew 16:1-17:27", title: "L'nasoto | \"To Test Him\"" },
      { torah: "Vayeshev", nt: "MAT.18.1-MAT.20.34", passage: "Matthew 18:1-20:34", title: "Hagadol | \"The Greatest\"" },
      { torah: "Miketz", nt: "MAT.21.1-MAT.23.12", passage: "Matthew 21:1-23:12", title: "Vayavou | \"And They Came\"" },
      { torah: "Vayigash", nt: "MAT.23.13-MAT.25.46", passage: "Matthew 23:13-25:46", title: "Oy Lachem | \"Woe to You\"" },
      { torah: "Vayechi", nt: "MAT.26.1-MAT.28.20", passage: "Matthew 26:1-28:20", title: "V'HaPesach | \"The Passover\"" },
      { torah: "Shemot", nt: "MRK.1.1-MRK.1.45", passage: "Mark 1:1-1:45", title: "B'sorat | \"The Good News\"" },
      { torah: "Va'eira", nt: "MRK.2.1-MRK.3.35", passage: "Mark 2:1-3:35", title: "Vayishmeu | \"And They Heard\"" },
      { torah: "Bo", nt: "MRK.4.1-MRK.5.20", passage: "Mark 4:1-5:20", title: "Hamon Amrav | \"A Great Multitude\"" },
      { torah: "Beshalach", nt: "MRK.5.21-MRK.6.44", passage: "Mark 5:21-6:44", title: "La'avor | \"Crossed Over\"" },
      { torah: "Yitro", nt: "MRK.6.45-MRK.7.37", passage: "Mark 6:45-7:37", title: "Shalcho | \"He Sent Them Away\"" },
      { torah: "Mishpatim", nt: "MRK.8.1-MRK.9.29", passage: "Mark 8:1-9:29", title: "Ma Yochelu | \"What Will They Eat\"" },
      { torah: "Terumah", nt: "MRK.9.30-MRK.10.52", passage: "Mark 9:30-10:52", title: "V'yaharguhu | \"And They Will Kill Him\"" },
      { torah: "Tetzaveh", nt: "MRK.11.1-MRK.12.44", passage: "Mark 11:1-12:44", title: "Vayehi | \"It Came to Pass\"" },
      { torah: "Ki Tisa", nt: "MRK.13.1-MRK.13.37", passage: "Mark 13:1-13:37", title: "Ha'avanim | \"The Stones\"" },
      { torah: "Vayakhel", nt: "MRK.14.1-MRK.14.72", passage: "Mark 14:1-14:72", title: "L'tofso | \"To Seize Him\"" },
      { torah: "Pekudei", nt: "MRK.15.1-MRK.16.20", passage: "Mark 15:1-16:20", title: "Vayolikhuhu | \"And They Led Him\"" },
      { torah: "Vayikra", nt: "LUK.1.1-LUK.2.52", passage: "Luke 1:1-2:52", title: "Ha'ma'asim | \"The Acts\"" },
      { torah: "Tzav", nt: "LUK.3.1-LUK.4.44", passage: "Luke 3:1-4:44", title: "Hayah Devar | \"The Word Came\"" },
      { torah: "Shemini", nt: "LUK.5.1-LUK.6.49", passage: "Luke 5:1-6:49", title: "Vehu Omed Al-Yad | \"He Stood By\"" },
      { torah: "Tazria", nt: "LUK.7.1-LUK.8.56", passage: "Luke 7:1-8:56", title: "Be'oznei Ha'am | \"In the Hearing of the People\"" },
      { torah: "Metzora", nt: "LUK.9.1-LUK.10.42", passage: "Luke 9:1-10:42", title: "Vayikra | \"And He Called\"" },
      { torah: "Acharei Mot", nt: "LUK.11.1-LUK.13.35", passage: "Luke 11:1-13:35", title: "Lammedeinu Lehitpallel | \"Teach Us To Pray\"" },
      { torah: "Kedoshim", nt: "LUK.14.1-LUK.16.31", passage: "Luke 14:1-16:31", title: "BaShabbat | \"On the Sabbath\"" },
      { torah: "Emor", nt: "LUK.17.1-LUK.19.48", passage: "Luke 17:1-19:48", title: 'Vayomer | "And He Said"' },
      { torah: "Behar", nt: "LUK.20.1-LUK.22.38", passage: "Luke 20:1-22:38", title: 'Vayigshu | "And They Approached"' },
      { torah: "Bechukotai", nt: "LUK.22.39-LUK.24.53", passage: "Luke 22:39-24:53", title: 'Vayelchu Acharav | "And They Went After Him"' },
      { torah: "Bamidbar", nt: "JHN.1.1-JHN.2.12", passage: "John 1:1-2:12", title: 'Bereshit | "In the Beginning"' },
      { torah: "Naso", nt: "JHN.2.13-JHN.4.54", passage: "John 2:13-4:54", title: 'Chag Ha-Pesach | "Destroy This Temple"' },
      { torah: "Behaalotecha", nt: "JHN.5.1-JHN.6.71", passage: "John 5:1-6:71", title: 'Chag La-Yehudim | "Rise, Take Up Your Mat"' },
      { torah: "Shlach", nt: "JHN.7.1-JHN.8.59", passage: "John 7:1-8:59", title: 'HaGalil | "Living Water"' },
      { torah: "Korach", nt: "JHN.9.1-JHN.10.42", passage: "John 9:1-10:42", title: 'Vayyar ish | "Born Blind"' },
      { torah: "Chukat", nt: "JHN.11.1-JHN.12.50", passage: "John 11:1-12:50", title: 'V\'sheshet Yamim | "Lazarus Come Forth"' },
      { torah: "Balak", nt: "JHN.13.1-JHN.15.27", passage: "John 13:1-15:27", title: 'Ha\'Aminu | "I Am the Vine"' },
      { torah: "Pinchas", nt: "JHN.16.1-JHN.18.27", passage: "John 16:1-18:27", title: 'Dibarti | "The Helper Will Come"' },
      { torah: "Matot", nt: "JHN.18.28-JHN.20.31", passage: "John 18:28-20:31", title: 'K\'Kalot | "My Kingdom Is Not of This World"' },
      { torah: "Masei", nt: "JHN.21.1-ACT.1.26", passage: "John 21:1-Acts 1:26", title: 'Me\'al Ha-Kever | "Follow Me"' },
      { torah: "Devarim", nt: "ACT.2.1-ACT.3.26", passage: "Acts 2:1-3:26", title: 'Teophilos | "Shavuot"' },
      { torah: "Va\'etchanan", nt: "ACT.4.1-ACT.5.42", passage: "Acts 4:1-5:42", title: 'Olim | "In One Accord"' },
      { torah: "Eikev", nt: "ACT.6.1-ACT.7.60", passage: "Acts 6:1-7:60", title: 'Chananiah | "Stephen\'s Testimony"' },
      { torah: "Re\'eh", nt: "ACT.8.1-ACT.9.43", passage: "Acts 8:1-9:43", title: 'Shaul | "Philip and the Ethiopian"' },
      { torah: "Shoftim", nt: "ACT.10.1-ACT.11.30", passage: "Acts 10:1-11:30", title: 'Kesarya | "Peter\'s Vision"' },
      { torah: "Ki Teitzei", nt: "ACT.12.1-ACT.13.52", passage: "Acts 12:1-13:52", title: 'Shalach | "Herod\'s Death"' },
      { torah: "Ki Tavo", nt: "ACT.14.1-ACT.15.41", passage: "Acts 14:1-15:41", title: 'Dat Moshe | "Paul and Barnabas"' },
      { torah: "Nitzavim", nt: "ACT.16.1-ACT.17.34", passage: "Acts 16:1-17:34", title: 'Vayavo | "Paul in Macedonia"' },
      { torah: "Vayelech", nt: "ACT.18.1-ACT.19.41", passage: "Acts 18:1-19:41", title: 'Ha-Achim | "Paul in Corinth"' },
      { torah: "Ha\'azinu", nt: "ACT.20.1-ACT.21.40", passage: "Acts 20:1-21:40", title: 'V\'Ish D\'Varim | "Paul\'s Journey to Jerusalem"' },
      { torah: "V\'Zot HaBerachah", nt: "ACT.22.1-ACT.28.31", passage: "Acts 22:1-28:31", title: 'Dineinu | "Paul\'s Defense"' }
    ];

    async function fetchTorahAndNT() {
      try {
        // Fetch current Torah portion from Sefaria API
        const torahResponse = await fetch("https://www.sefaria.org/api/calendars");
        const torahData = await torahResponse.json();

        // Extract the true Torah portion name from displayValue.en
        const currentTorah = torahData.calendar_items.find(item =>
          item.title.en.includes("Parashat")
        )?.displayValue.en;

        setTorahPortion(currentTorah || "Unknown Torah Portion");


       // Find corresponding NT passage and title
       const mapping = torahToNTMapping.find(item => item.torah === currentTorah);
       if (mapping) {
         setNtPassage(mapping.passage);
         setNtTitle(mapping.title);

         // Fetch NT passage content
         const ntResponse = await fetch(
           `https://api.scripture.api.bible/v1/bibles/f72b840c855f362c-04/passages/${encodeURIComponent(mapping.nt)}`,
           {
             headers: {
               "api-key": "15031d076b0791b503e9c13b013e5a39",
             },
           }
         );
         const ntData = await ntResponse.json();
         setNtText(ntData.data.content || "Passage content not available.");
       } else {
         setNtPassage("No corresponding New Testament passage found.");
         setNtTitle("");
         setNtText("");
       }
     } catch (error) {
       console.error("Error fetching data:", error);
       setNtText("An error occurred while fetching the New Testament passage.");
     }
   }

    fetchTorahAndNT();
  }, []);

  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <h2>HaBesorah Gospel Portion of the Week</h2>
      <h4>{ntTitle}</h4>
      <h5>{ntPassage}</h5>
      <h6>
        {new Date().toLocaleDateString("en-us", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </h6>

      <div className="bible-chapters-buttons">
      <p className="gospel-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ntText), }} />

        <br></br>
        <br></br>
      </div>
      <br></br>
      <br></br>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    chapter: state.chapter,
    loading: state.loading,
    error: state.error,
  };
};

export default connect(mapStateToProps, { getChapter })(NewTestamentPortion);
